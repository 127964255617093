// Generated by Framer (b0f2619)

import { addFonts, cx, CycleVariantState, getFonts, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Video } from "https://framerusercontent.com/modules/lRDHiNWNVWmE0lqtoVHP/IZ0vSV62Dv7ax4rBiGUk/Video.js";
const VideoFonts = getFonts(Video);

const enabledGestures = {"I1285:204523;59:22236": {hover: true}};

const cycleOrder = ["I1285:204523;59:22236"];

const serializationHash = "framer-kZNqu"

const variantClassNames = {"I1285:204523;59:22236": "framer-v-uj62ah"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "I1285:204523;59:22236", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} background={{alt: "", fit: "fill", sizes: "min(599.7109px, 100vw)", src: "https://framerusercontent.com/images/qVbQL9BjtaCY37FLWEZa7GSE9H4.png", srcSet: "https://framerusercontent.com/images/qVbQL9BjtaCY37FLWEZa7GSE9H4.png?scale-down-to=512 512w, https://framerusercontent.com/images/qVbQL9BjtaCY37FLWEZa7GSE9H4.png?scale-down-to=1024 1024w, https://framerusercontent.com/images/qVbQL9BjtaCY37FLWEZa7GSE9H4.png 1201w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-uj62ah", className, classNames)} data-framer-name={"Default"} initial={variant} layoutDependency={layoutDependency} layoutId={"I1285:204523;59:22236"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, ...style}} {...addPropertyOverrides({"I1285:204523;59:22236-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-xixx59-container"} layoutDependency={layoutDependency} layoutId={"O0HtZv7FB-container"}><Video backgroundColor={"rgba(0, 0, 0, 0)"} borderRadius={0} bottomLeftRadius={0} bottomRightRadius={0} controls={false} height={"100%"} id={"O0HtZv7FB"} isMixedBorderRadius={false} layoutId={"O0HtZv7FB"} loop muted objectFit={"cover"} playing posterEnabled={false} srcFile={"https://framerusercontent.com/assets/lxz2ToOFTlA2Klh310C6WwZMMiw.mp4"} srcType={"Upload"} srcUrl={"https://assets.mixkit.co/videos/preview/mixkit-ice-cream-glass-of-red-soda-5094-small.mp4"} startTime={0} style={{height: "100%", width: "100%"}} topLeftRadius={0} topRightRadius={0} volume={25} width={"100%"}/></motion.div></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-kZNqu[data-border=\"true\"]::after, .framer-kZNqu [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-kZNqu.framer-f31vg8, .framer-kZNqu .framer-f31vg8 { display: block; }", ".framer-kZNqu.framer-uj62ah { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 380px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 600px; will-change: var(--framer-will-change-override, transform); }", ".framer-kZNqu .framer-xixx59-container { flex: 1 0 0px; height: 1px; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-kZNqu.framer-uj62ah { gap: 0px; } .framer-kZNqu.framer-uj62ah > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-kZNqu.framer-uj62ah > :first-child { margin-top: 0px; } .framer-kZNqu.framer-uj62ah > :last-child { margin-bottom: 0px; } }", ".framer-kZNqu.framer-v-uj62ah.hover.framer-uj62ah { aspect-ratio: 1.5781866554416086 / 1; height: var(--framer-aspect-ratio-supported, 380px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 380
 * @framerIntrinsicWidth 600
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"c2v4FygFv":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerkDjMSx8gt: React.ComponentType<Props> = withCSS(Component, css, "framer-kZNqu") as typeof Component;
export default FramerkDjMSx8gt;

FramerkDjMSx8gt.displayName = "Video";

FramerkDjMSx8gt.defaultProps = {height: 380, width: 600};

addFonts(FramerkDjMSx8gt, [...VideoFonts])